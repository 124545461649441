import { atom } from "recoil";

type Operation = {
  text: string;
  onClick: () => void;
};

type Icon = "check";

export type Options = {
  icon?: Icon;
  operation?: Operation;
};

type Toast = {
  id: string;
  text: string;
  options?: Options;
};

export const toastsState = atom<Toast[]>({
  key: "toastsState",
  default: [],
});
