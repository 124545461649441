import type { NextPage } from "next";
import { Top } from "../components/pages/Top";
import { Seo } from "../components/utils/Seo";
import { i18n } from "../i18n";

const TopPage: NextPage = () => {
  return (
    <>
      <Seo
        type="website"
        title={i18n.topPageTitle}
        description={i18n.topPageDescription}
      />

      <Top />
    </>
  );
};

export default TopPage;
