import { i18n } from "../../../i18n";
import Link from "next/link";
import { Layout } from "../../ui/Layout";
import { TopPageHeaderMenu } from "./TopPageHeaderMenu";
import { pagesPath } from "../../../utils/$path";
import { Toast } from "../../ui/Toast";

export const Top: React.FC = () => {
  const links = [
    {
      href: pagesPath.items.$url(),
      title: i18n.itemList,
    },
    {
      href: pagesPath.brands.$url(),
      title: i18n.brandList,
    },
  ] as const;

  return (
    <>
      <Layout header={<TopPageHeaderMenu />}>
        <div className="p-5">
          <h1 className="text-4xl font-bold">giff letter</h1>

          <ul className="mt-10 ml-6 list-disc">
            {links.map((link) => (
              <li key={link.href.pathname}>
                <Link href={link.href} className="text-sm hover:underline">
                  {link.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </Layout>
      <Toast offset="bottom-menu-height" />
    </>
  );
};
