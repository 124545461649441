import { HeaderMenu } from "../../ui/HeaderMenu";
import { PrettyLink } from "../../ui/PrettyLink";
import { NotificationIcon } from "../../icons/NotificationIcon";
import { pagesPath, staticPath } from "../../../utils/$path";
import { CartBox } from "../../ui/HeaderMenu/CartBox";

export const TopPageHeaderMenu: React.FC = () => {
  return (
    <HeaderMenu
      left={
        <PrettyLink href={pagesPath.notifications.$url()}>
          <NotificationIcon />
        </PrettyLink>
      }
      center={
        <PrettyLink href="/">
          <img
            src={staticPath.assets.logo_png}
            width={115}
            height={26}
            alt="giff letter"
            decoding="async"
            loading="lazy"
          />
        </PrettyLink>
      }
      right={<CartBox />}
    />
  );
};
