import { default as clsx } from "clsx";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useRecoilState } from "recoil";
import { match } from "ts-pattern";
import { toastsState } from "../../../recoil/toastState";
import { unwrap } from "../../../utils/unwrap";

const ToastCheckIcon: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM10.5355 15.1924C10.8284 15.4853 11.2578 15.5585 11.6189 15.4121L11.9497 15.1924L11.9509 15.1912L16.1924 10.9498C16.5829 10.5592 16.5829 9.92606 16.1924 9.53554C15.8019 9.14501 15.1687 9.14501 14.7782 9.53554L11.2426 13.0711L9.12132 10.9498C8.7308 10.5592 8.09763 10.5592 7.70711 10.9498C7.31658 11.3403 7.31658 11.9734 7.70711 12.364L10.5355 15.1924Z"
        fill="white"
      />
    </svg>
  );
};

type ToastOffset = "0" | "48px" | "92px" | "132px" | "bottom-menu-height";

type Props = {
  offset?: ToastOffset;
};

export const Toast: React.FC<Props> = ({ offset = "0" }) => {
  const [toasts, setToasts] = useRecoilState(toastsState);
  const [isShow, setIsShow] = useState(false);

  const closeToast = (id: string) => {
    setToasts((toasts) => toasts.filter((toast) => toast.id !== id));
  };

  useEffect(() => {
    setIsShow(typeof document !== "undefined");
  }, []);

  if (!isShow) {
    return null;
  }

  return createPortal(
    <div
      className={clsx(
        "fixed inset-x-0 z-20 m-5 space-y-5",
        match(offset)
          .with("0", () => "bottom-0")
          .with("48px", () => "bottom-[48px]")
          .with("92px", () => "bottom-[92px]")
          .with("132px", () => "bottom-[132px]")
          .with("bottom-menu-height", () => "bottom-bottom-menu-height")
          .exhaustive(),
      )}
    >
      {toasts.map(({ id, text, options }) => (
        <div
          className="flex h-[46px] items-center rounded-[4px] bg-text px-4 text-xs drop-shadow-toast"
          key={id}
        >
          {options?.icon !== undefined && (
            <span aria-hidden className="mr-1">
              {match(options.icon)
                .with("check", () => <ToastCheckIcon />)
                .exhaustive()}
            </span>
          )}
          <div className="flex-grow font-bold text-white">{text}</div>
          {options?.operation !== undefined && (
            <div className="ml-3 flex-none">
              <button
                type="button"
                className="font-bold text-brand"
                onClick={() => {
                  unwrap(options.operation).onClick();
                  closeToast(id);
                }}
              >
                {options.operation.text}
              </button>
            </div>
          )}
        </div>
      ))}
    </div>,
    document.body,
  );
};
